<template>
    <div class="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed" style="">
        <!--begin::Content-->
        <div
            class="text-end me-5 mt-5"
            v-if="
                this.$store.getters.currentSettings &&
                this.$store.getters.currentSettings.en &&
                this.$store.getters.currentSettings.fr &&
                this.$route.name !== 'sign-in' &&
                this.$route.name !== 'password-reset' && 
                this.$route.name !== 'change-password' &&
                this.$route.name !== 'password-reset' &&
                this.$route.name !== 'auth-callback' &&
                this.$route.name !== 'auth-logout' 
            "
        >
            <a v-if="getLocaleLang() === 'en'" v-on:click="setLocale('fr')" class="btn btn-icon btn-light">
                <img alt="Logo" src="/media/france.png" class="w-50" />
            </a>
            <a v-else-if="getLocaleLang() === 'fr'" v-on:click="setLocale('en')" class="btn btn-icon btn-light">
                <img alt="Logo" src="/media/royaume-uni.png" class="w-50" />
            </a>
        </div>
        <div class="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
            <!--begin::Logo-->
            <!--end::Logo-->
            <!--begin::Logo-->
            <!--end::Logo-->
            <router-view></router-view>
            <a href="https://www.obea.fr/" target="_blank" class="mt-12">
                <img alt="Logo" src="/media/logo_obea.svg" class="h-45px" />
            </a>
        </div>
        <!--end::Content-->
    </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, onUnmounted, watch } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
    name: "auth",
    components: {},
    data() {
        return {
            getLocaleLang: function () {
                return localStorage.locale;
            },
        };
    },
    methods: {
        setLocale: function (locale) {
            localStorage.locale = locale;
            location.reload();
        },
    },
    setup() {
        const store = useStore();
        const route = useRoute();
        const router = useRouter();
        onMounted(() => {
            store.dispatch(Actions.ADD_BODY_CLASSNAME, "bg-body");
            if (store.getters.isUserAuthenticated) {
                router.push({ name: "dashboard" });
            }
        });

        onUnmounted(() => {
            store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "bg-body");
        });
        watch(
            () => route.path,
            () => {
                if (store.getters.isUserAuthenticated) {
                    router.push({ name: "dashboard" });
                }
            }
        );
    },
});
</script>
